import { Platform } from '@ionic/angular/standalone';
import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';

export const MOBILE_WIDTH = 1080;

export const VERSION = '1.2.2';
export const BUILD_NUMBER = '122';

@Injectable({ providedIn: 'root' })
export default class applicationInfoService {
  private _isMobileResolution: boolean = false;
  _appInfo: AppInfo | null = null;

  _version = `${VERSION} (${BUILD_NUMBER})`;

  constructor(private readonly platform: Platform) {
    this.isMobileResolution = window.innerWidth <= MOBILE_WIDTH;
    this.getAppInfo();
  }

  set isMobileResolution(value: boolean) {
    this._isMobileResolution = value;
  }

  get isMobileResolution(): boolean {
    return this._isMobileResolution;
  }

  get isPlatformMobile(): boolean {
    return this.platform.is('android') || this.platform.is('ios');
  }

  get isApp(): boolean {
    return this.platform.is('capacitor');
  }

  get isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  get isAndroid(): boolean {
    return this.platform.is('android');
  }

  get isIos(): boolean {
    return this.platform.is('ios');
  }

  async getAppInfo() {
    if (this.isApp) {
      this._appInfo = await App.getInfo();
      this._version = `${this._appInfo.version} (${this._appInfo.build})`;
    }
  }

  get version(): string {
    return this._version;
  }

  get appInfo(): AppInfo | null {
    return this._appInfo;
  }
}
